import React, { useEffect, useRef } from "react"
import { Box, CircularProgress, Paper } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import { PubSub } from "../../../utils/pubsub"
import { red } from "@material-ui/core/colors"

export const LOADING_PUBSUB_TOPIC = "LOADING_PUBSUB_TOPIC"
export const UNLOADING_PUBSUB_TOPIC = "UNLOADING_PUBSUB_TOPIC"

const BigLoading = (props) => {
  const theme = useTheme()

  const [count, setCount] = React.useState(0)
  const countRef = useRef(count)

  useEffect(() => {
    const onLoadingSub = PubSub.getObservableWithTopic(
      LOADING_PUBSUB_TOPIC
    ).subscribe((res) => {
      setCount(count + 1)
    })

    const onUnloadingSub = PubSub.getObservableWithTopic(
      UNLOADING_PUBSUB_TOPIC
    ).subscribe((res) => {
      setCount(count - 1)
    })

    return () => {
      onLoadingSub.unsubscribe()
      onUnloadingSub.unsubscribe()
    }
  })

  return (
    <>
      {count > 0 && (
        <Box style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          zIndex: 9999999,
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: `${theme.palette.background.primary}99`,
        }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default BigLoading
