import { configureStore } from "@reduxjs/toolkit"

import internalSlice from "./internal"

const Store = configureStore({
  reducer: {
    internal: internalSlice,
  },
})

export default Store
