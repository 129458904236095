import { createTheme } from "@material-ui/core/styles"

const primary = "#006FFF" // #F47822
const borderColor = "#ccc"
const borderRadius = 4
const labelsPrimary = "#212121"

const original = createTheme() // original

const backgroundColorTableRows = "rgba(0, 0, 0, 0.04)"
const borderColorTable = " #e0e0e0"

const backgroundColorSecondary = "#fafafa"

const theme = createTheme({
  typography: {
    typography: {
      fontFamily: "Roboto",
    },
    h1: {
      fontWeight: "bold",
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "3rem",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "2.5rem",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "2rem",
    },
    h5: {
      fontWeight: "bold",
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      textTransform: "none",
    },
    fontFamily: ["Roboto"].join(","),
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#E9E9EA !important",
        },
      },
    },
    MuiToggleButton: {
      // Override the styling for selected toggle buttons
      root: {
        borderColor: primary,
        color: primary,
        minWidth: "10rem",
        lineHeight: "normal",
        "&.Mui-selected": {
          backgroundColor: primary,
          borderColor: primary,
          color: "#ffffff",
        },
        "&:hover ": {
          backgroundColor: primary,
          borderColor: primary,
          color: "#ffffff",
        },
      },
    },
    MuiSelect: {
      root: { border: `1px solid ${borderColor} !important` },
      select: {
        borderRadius,
      },
    },
    MuiFormLabel: {
      root: {
        color: `${labelsPrimary} !important`,
        fontWeight: "bold",
        position: "relative !important",
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${borderColor}`,
      },
    },
    MuiTab: {
      root: {
        padding: original.spacing(3),
        "&.Mui-selected": {
          fontWeight: "bold",
        },
      },
    },
    MuiInputBase: {
      root: {
        "label + &": {
          marginTop: original.spacing(2),
        },
      },
      input: {
        padding: original.spacing(1.5),
        border: `1px solid ${borderColor}`,
        borderRadius,
        background: "white",
        "&:focus": {
          borderRadius,
          border: "1px solid",
          borderColor: primary,
          fontSize: "1rem",
        },
      },
      inputMultiline: {
        padding: original.spacing(1.5),
      },
      adornedStart: {
        "& svg": {
          position: "absolute",
          left: "0.5rem",
        },
        "& input": {
          paddingLeft: original.spacing(4),
        },
      },
    },
    MuiInput: {
      // datepiker
      input: {
        marginTop: original.spacing(2),
        border: "0 !important",
      },
    },
  },
  palette: {
    common: {
      white: "#ffffff",
      black: "#000000",
    },
    primary: {
      contrastText: "#fff",
      light: "#dceaff", //#FDB913
      main: "#006FFF", // #F47822
      dark: "#00055f", // #BB4900
    },
    secundary: {
      primary: "#000000",
      secundary: "#2C2C2C",
      main: "#2C2C2C",
      tertiary: "#000000",
    },
    labels: {
      primary: "#212121",
      secundary: "#757575",
      tertiary: "#E9E9EA",
      quaternary: "#F9F8FB",
      disable: "#9E9E9E",
    },
    background: {
      primary: "#FFFFFF",
      secundary: backgroundColorSecondary,
      tertiary: "#EEEEEE",
      disable: "#CCCCCC",
      color: "rgba(149, 123, 0, 0.1)",

      paper: "#fff",
      default: backgroundColorSecondary,
    },
    fills: {
      primary: "#F5F5F5",
      secundary: "#E9E9EB",
      tertiary: "#DFDFDF",
    },
    separator: {
      primary: "#E0E0E0",
      secundary: "#8E8E8E",
    },
    placeHolder: {
      primary: "#A7A7A7",
    },
    errors: {
      primary: "#B00020",
    },
    defaults: {
      borders: "#ccc",
      placeholders: "#a2a2a2",
      headerMenuColor: "#fff",
    },
    others: {
      backGreen: "#e8f1dd",
      textGreen: "#709839",
      backRed: "#edd2db",
      textRed: "#a1395e",
    },
  },
})

theme.shadows = theme.shadows.map((s) =>
  s.replace(new RegExp("rgba\\(0,0,0,", "g"), "rgba(210,210,210,")
)

export default theme
