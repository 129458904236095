import { useTheme } from "@material-ui/core"
import React from "react"

export default function Splash(props) {
  const theme = useTheme()
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgb(255, 255, 255)",
        color: theme.palette.primary.main,
      }}
    >
      <h1 style={{
        fontSize: window.innerWidth <= 415 ? "52px" : "72px",
      }}>A carregar...</h1>
    </div>
  )
}
