import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const internalSlice = createSlice({
  name: "internal",
  initialState: {
    alertShow: false,
    alertContent: {
      message: "",
      severity: "info",
    },
  },
  reducers: {
    setAlertShow(state, action) {
      return _.merge({}, state, { alertShow: action.payload })
    },
    setAlertContent(state, action) {
      return _.merge({}, state, { alertContent: action.payload })
    },
  },
})

const { actions, reducer } = internalSlice

export const { setAlertShow, setAlertContent } = actions

export default reducer
