import React, { useEffect, useRef, useState } from "react"
import { tlci, mmci, mw } from "./themes"
import Shell from "./components/Shell"
import "./App.css"

const getTheme = (theme = "tlci") => {
  let selectedTheme = tlci
  switch (theme) {
    case "mw":
      selectedTheme = mw
      break
    case "mmci":
      selectedTheme = mmci
      break
  }

  return selectedTheme
}

function App(props) {
  document.getElementsByTagName("body")[0].className = process.env.REACT_APP_THEME;
  return (
    <>
      <Shell theme={getTheme(process.env.REACT_APP_THEME)} />
    </>
  )
}

export default App
