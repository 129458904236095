import _ from "lodash"
import { isDefined } from "./general"

export function navigate(endpoint, params, blank = false) {
  let qParam = ""
  if (!endpoint) {
    return
  }

  if (params) {
    const intr = new URLSearchParams(params)
    qParam += "?" + intr.toString()
  }
  const location = `${endpoint}${qParam}`

  if (blank) {
    let url = `${window.location.host}${location}`
    if (!url.includes("http")) url = `${window.location.protocol}//${url}`
    window.open(url, "_blank")
    return
  }

  window.location.href = (location || "").startsWith("/")
    ? location
    : `/${location}`
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const currentPathWithIsEqualTo = (path) => {
  if (!isDefined(path)) return false

  let toComparePath = path
  let currentPath = window.location.pathname
  if (!toComparePath.startsWith("/")) toComparePath = `/${path}`

  return toComparePath.toLowerCase() === currentPath.toLowerCase()
}
