import React, { Component, Suspense, lazy, useEffect, useState } from "react"

import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { useDispatch, useSelector } from "react-redux"

function Alert() {
  const dispatch = useDispatch()
  const { alertShow, alertContent } = useSelector((state) => state.internal)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    dispatch({
      type: "internal/setAlertShow",
      payload: false,
    })
  }

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertShow}
        onClose={handleClose}
        autoHideDuration={4000}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={alertContent.severity || "info"}
          onClose={handleClose}
        >
          {alertContent.message || ""}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  )
}

export default Alert
