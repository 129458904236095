import { Subject } from "rxjs"
import { filter } from "rxjs/operators"

const subject = new Subject()

export const PubSub = {
  publishWithTopic: (topic, component, data) =>
    subject.next({ topic, component, data }),
  getObservableWithTopic: (topic) =>
    subject.pipe(filter((f) => f.topic === topic)),
  publish: (component, data) => subject.next({ component, data }),
  getObservable: () => subject.asObservable(),
}
