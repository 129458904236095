import _ from "lodash"
import axios from "axios"
import Store from "../stores/store"
import { navigate } from "./router"
import { PubSub } from "./pubsub"
import {
  LOADING_PUBSUB_TOPIC,
  UNLOADING_PUBSUB_TOPIC,
} from "../components/shared/big-loading/big-loading"

window.hasFallbackErrorHandle = false

const getOptions = (options) => {
  const userJWT = localStorage.getItem("jwt")
  const userRefreshToken = localStorage.getItem("refreshToken")
  const defaultOptions = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",

      Authorization: `Bearer ${userJWT}`,
      Refresh: `${userRefreshToken}`,
    },
    withCredentials: true,
  }
  return _.assign({}, defaultOptions, options)
}

function handleError(error, queryParams = null) {
  console.error(error)
  if (_.has(error, "response.status") && error.response.status == 422) {
    const message = _.has(error, "response.data.returnCode")
      ? `${error.response.data?.message}`
      : error.message

    Store.dispatch({
      type: "internal/setAlertContent",
      payload: {
        message,
        severity: "error",
      },
    })

    Store.dispatch({
      type: "internal/setAlertShow",
      payload: true,
    })
  } else if (_.has(error, "response.status")) {
    handleErrorPage(error.response?.status, queryParams)
  }
}

export function handleErrorPage(statusCode, queryParams = null) {
  if (_.has(window.error_pages, statusCode)) {
    navigate(window.error_pages[statusCode], queryParams)
  } else {
    if (!window.hasFallbackErrorHandle) {
      const errorPages = httpGet(
        `${process.env.REACT_APP_PROXY_PORTAL_URL}/error-pages`,
        undefined,
        false
      ).then((response) => {
        if (response.status == 200 && _.has(response, "data")) {
          const errorPages = response.data
          if (_.isUndefined(window.error_pages)) {
            window.error_pages = {}
          }
          _.each(errorPages, (page) => {
            if (_.has(page, "path") && page.path) {
              window.error_pages[page.error] = page.path
            }
          })

          if (_.has(window.error_pages, statusCode)) {
            navigate(window.error_pages[statusCode], queryParams)
          }
        }
      })
    }
    window.hasFallbackErrorHandle = true
  }
}

export function httpGet(
  url = "",
  options = {},
  proxy = true,
  queryParamsForErrorPage = null,
  withLoading = false
) {
  const requestOptions = getOptions(options)
  let requestUrl = url
  if (proxy) {
    requestUrl = `${process.env.REACT_APP_PROXY_PORTAL_URL}/request`
    requestOptions.headers["Forwarded"] = url
  }

  if (withLoading) PubSub.publishWithTopic(LOADING_PUBSUB_TOPIC)

  return axios
    .get(requestUrl, requestOptions)
    .then((res) => {
      if (withLoading) PubSub.publishWithTopic(UNLOADING_PUBSUB_TOPIC)
      return res
    })
    .catch((error) => {
      if (withLoading) PubSub.publishWithTopic(UNLOADING_PUBSUB_TOPIC)
      return handleError(error, queryParamsForErrorPage)
    })
}

export function httpPost(
  url = "",
  data = {},
  options = {},
  proxy = true,
  withLoading = false
) {
  const requestOptions = getOptions(options)
  let requestUrl = url
  if (proxy) {
    requestUrl = `${process.env.REACT_APP_PROXY_PORTAL_URL}/request`
    requestOptions.headers["Forwarded"] = url
  }

  if (withLoading) PubSub.publishWithTopic(LOADING_PUBSUB_TOPIC)

  return axios
    .post(requestUrl, data, requestOptions)
    .then((res) => {
      if (withLoading) PubSub.publishWithTopic(UNLOADING_PUBSUB_TOPIC)
      return res
    })
    .catch((error) => {
      if (withLoading) PubSub.publishWithTopic(UNLOADING_PUBSUB_TOPIC)
      return handleError(error)
    })
}

export function httpPut(
  url = "",
  data = {},
  options = {},
  proxy = true,
  withLoading = false
) {
  const requestOptions = getOptions(options)
  let requestUrl = url
  if (proxy) {
    requestUrl = `${process.env.REACT_APP_PROXY_PORTAL_URL}/request`
    requestOptions.headers["Forwarded"] = url
  }

  if (withLoading) PubSub.publishWithTopic(LOADING_PUBSUB_TOPIC)

  return axios
    .put(requestUrl, data, requestOptions)
    .then((res) => {
      if (withLoading) PubSub.publishWithTopic(UNLOADING_PUBSUB_TOPIC)
      return res
    })
    .catch((error) => {
      if (withLoading) PubSub.publishWithTopic(UNLOADING_PUBSUB_TOPIC)
      return handleError(error)
    })
}

export function logError(
  code = "",
  options = {},
  page = "",
  component = "",
  data = {}
) {
  const requestOptions = getOptions(options)
  let requestUrl = `${process.env.REACT_APP_PROXY_PORTAL_URL}/error`

  return axios
    .post(requestUrl, { errorCode: component, code, ...data }, requestOptions)
    .catch((error) => handleError(error))
}

export function httpPatch(url = "", data = {}, options = {}, proxy = true) {
  const requestOptions = getOptions(options)
  let requestUrl = url
  if (proxy) {
    requestUrl = `${process.env.REACT_APP_PROXY_PORTAL_URL}/request`
    requestOptions.headers["Forwarded"] = url
  }
  return axios
    .patch(requestUrl, data, requestOptions)
    .catch((error) => handleError(error))
}

export function httpDelete(url = "", options = {}, proxy = true) {
  const requestOptions = getOptions(options)
  let requestUrl = url
  if (proxy) {
    requestUrl = `${process.env.REACT_APP_PROXY_PORTAL_URL}/request`
    requestOptions.headers["Forwarded"] = url
  }
  return axios
    .delete(requestUrl, requestOptions)
    .then((res) => {
      return res 
    })
    .catch((error) => handleError(error))
}

// Do nothing. Used to add host to the endpont path, however this was moved to proxy server.
// Keep this as a shortcut for future implementation regarding to host.
export const getUrl = (endpoint) => endpoint

export function getUrlByPageId(id) {
  const PAGE_ID_TO_PATH_URL = `${process.env.REACT_APP_PROXY_PORTAL_URL}/page`
  const userJWT = localStorage.getItem("jwt")
  const userRefreshToken = localStorage.getItem("refreshToken")

  return httpGet(
    // eslint-disable-next-line no-undef
    `${PAGE_ID_TO_PATH_URL}?id=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${userJWT}`,
        Refresh: `${userRefreshToken}`,
      },
      withCredentials: false,
    },
    false
  )
}
