import React, { lazy, useEffect, useState } from "react"
import * as _ from "lodash"
import { Box, Paper, useTheme } from "@material-ui/core"
import { isEmpty } from "lodash"
import { handleErrorPage, logError } from "../utils/http"

import { mobileStyle } from "../themes/globalTheme"
import { useWindowSize } from "../utils/general"

const getColumnGutter = (
  columnList = [],
  gutterSpacing = window.innerWidth * 0.02
) => {
  if (columnList.length <= 1) return 0

  const numGutter = columnList.length - 1 // 3 columns has 2 gutters []<->[]<->[]
  return (numGutter * gutterSpacing) / columnList.length
}

const importView = (viewName) =>
  lazy(() =>
    import(`../${viewName}`).catch(() => {
      logError("FW0002", {}, window.location.pathname, viewName, {})
      handleErrorPage("404")
      return import(`../components/Null`)
    })
  )

export default function Layout(props) {
  const [columnsList, setColumnsList] = useState([])
  const [columnProps, setColumnProps] = useState([])
  const theme = useTheme()
  const mobile = mobileStyle()

  const { noHeader } = props

  const mobileBreakpoint = props.configs?.mobile_breakpoint || 500

  const component = props?.components[0].registry.location

  //const widthSize = useWindowSize();

  useEffect(() => {
    function mapComponents() {
      const columns = props?.configs?.columns
      let orderedColumns = []
      const components = props?.components
      let layoutComps = []
      setColumnProps(columns)

      // Initialize layoutComps for columns length
      for (let index = 0; index < columns?.length; index++) {
        layoutComps[index] = []
      }

      // Map components to corresponding columns
      _.each(components, (viewObj) => {
        // TODO implement display_mobile(boolean) to handle active comps in mobileBreakpoint
        layoutComps[viewObj?.layout_conf?.column_number - 1]?.push(viewObj)
      })

      layoutComps = _.map(layoutComps, (layouts) => {
        return _.sortBy(layouts, ["layout_conf.seq_number"])
      })

      // Get components split by columns
      orderedColumns = _.values(layoutComps)

      setColumnsList(orderedColumns)
    }
    mapComponents()
  }, [props?.components, props?.configs])

  const findMarginBottom = () => {
    switch (component) {
      case "components/login":
        return theme.spacing(0)
      case "components/reset-password":
        return theme.spacing(0)
      case "components/confirm-registration":
        return theme.spacing(0)
      default:
        return theme.spacing(2.125)
    }
  }

  return (
    <div
      className={mobile.root}
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: window.innerWidth <= mobileBreakpoint ? "column" : "row",
        justifyContent:
          window.innerWidth <= mobileBreakpoint ? "normal" : "space-between",
        overflow: "auto",
        minHeight: noHeader ? "100%" : "calc(100% - 130px)",
      }}
    >
      {columnsList.map((col, colIndex) => (
        <div
          key={colIndex}
          style={{
            display: "flex",
            flexDirection: "column",

            width:
              window.innerWidth <= mobileBreakpoint
                ? "100%"
                : `calc(${columnProps[colIndex]}% - ${getColumnGutter(
                    columnsList,
                    theme.spacing(2)
                  )}px)`,
          }}
        >
          {col.map((comp, index) => {
            const View = importView(comp?.registry?.location)
            const viewProps = comp?.data || {}

            if (
              _.has(viewProps, "routing.error_pages") &&
              _.isEmpty(window.error_pages)
            ) {
              window.error_pages = viewProps.routing.error_pages
            }

            const isMobile = window.innerWidth <= mobileBreakpoint
            const isActive = isMobile
              ? comp.layout_conf.display_mobile
              : comp.active
            return (
              isActive && (
                <Box
                  key={index}
                  style={{
                    marginBottom: findMarginBottom(),
                  }}
                >
                  <View
                    key={index}
                    {...viewProps}
                    style={{
                      flexGrow: 1,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
              )
            )
          })}
        </div>
      ))}
    </div>
  )
}
