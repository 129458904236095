import { makeStyles } from "@material-ui/core/styles"

const backgroundColorTableRows = "rgba(0, 0, 0, 0.04)"

const backgroundColorCounterInput = "#cfcfcf"

export const row = makeStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: backgroundColorTableRows,
    },
  },
})

export const drawer = makeStyles({
  root: {
    "& .MuiDrawer-paper": {
      width: '100%',
      maxWidth: "600px",
    },
  },
})

export const counterInput = makeStyles({
  root: {
    "& .MuiInput-input": {
      marginTop: 0,
      height: 32,
      width: 32,
      padding: 0,
      borderRadius: 0,
      backgroundColor: backgroundColorCounterInput,
      textAlign: "center",
    },
  },
})

export const rowColor = (row) => {
  return row % 2
    ? { background: "white" }
    : { background: backgroundColorTableRows }
}

export const mobileStyle = makeStyles((theme) => ({
  root: {
    paddingLeft: "min(6%, 76px)",
    paddingRight: "min(6%, 76px)",
    [theme.breakpoints.down(916)]: {
      paddingLeft: theme.spacing(1.875),
      paddingRight: theme.spacing(1.875),
    },
  },
}))
