import React, { Suspense, lazy, useEffect, useState } from "react"
import axios from "axios"
import _ from "lodash"

import { handleErrorPage, httpGet, logError } from "../utils/http"
import { ThemeProvider } from "@material-ui/core/styles"

import NotFound from "../pages/NotFound"
import Splash from "./Splash"
import Layout from "../layouts/Layout"

import Alert from "./Alert"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, useTheme } from "@material-ui/core"
import BigLoading from "./shared/big-loading/big-loading"

const importView = (viewName) =>
  lazy(() =>
    import(`../${viewName}`).catch(() => {
      logError("FW0002", {}, window.location.pathname, viewName, {})
      handleErrorPage("404")
      return import(`./Null`)
    })
  )

function fetchPages(pathname, search = "") {
  return new Promise((resolve, reject) => {
    let userJWT = localStorage.getItem("jwt")
    let userRefreshToken = localStorage.getItem("refreshToken")

    httpGet(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_PROXY_PORTAL_URL}?id=${process.env.REACT_APP_PROXY_PORTAL_ID}&path=${pathname}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userJWT}`,
          Refresh: `${userRefreshToken}`,
        },
        withCredentials: false,
      },
      false,
      { redirectTo: `${pathname}${search}` }
    )
      .then((response) => {
        if (_.has(response, "status") && response.status === 200) {
          resolve(response.data)
        } else {
          console.error("ERROR FetchPage: ", "Something went wrong!")
        }
      })
      .catch(function (error) {
        console.error("ERROR FetchPage: ", error)
        reject(null)
      })
  })
}

function Shell(props) {
  const theme = useTheme()

  const [components, setComponents] = useState([])
  const [configs, setConfigs] = useState([])
  const [header, setHeader] = useState([])
  const [footer, setFooter] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    async function loadViews() {
      try {
        const lazyViews = await fetchPages(
          window.location.pathname,
          window.location.search
        )

        if (!lazyViews) {
          throw Error("Page location fetch not found!")
        }

        // Load Header
        const HeaderView = await importView(
          lazyViews.header?.component?.registry?.location
        )
        if (lazyViews.header) {
          const headerProps = lazyViews?.header?.component?.data || {}

          setHeader(
            <HeaderView key={`header_${lazyViews.id}`} {...headerProps} />
          )
        } else {
          setHeader(null)
        }
        // Load Footer
        const FooterView = await importView(
          lazyViews.footer?.component?.registry?.location
        )
        if (lazyViews.footer) {
          const footerProps = lazyViews?.footer?.component?.data || {}

          setFooter(
            <FooterView key={`footer_${lazyViews.id}`} {...footerProps} />
          )
        } else {
          setFooter(null)
        }

        // Load Components
        const componentPromises = _.compact(lazyViews.components)
        Promise.all(componentPromises)
          .then(setComponents)
          .then(() => {
            // Layout Config
            const layoutConfig = lazyViews?.layout_config
            setConfigs(layoutConfig)
            setIsLoading(false)
          })

        // // Layout Config
        // const layoutConfig = lazyViews?.layout_config;
        // setConfigs(layoutConfig);
      } catch (error) {
        // 404 Page Not Found
        console.error(error)
        setComponents([<NotFound key={"not-found"} />])
        setIsLoading(false)
      }
    }
    loadViews()
  }, [props])

  return (
    <React.Fragment>
      <ThemeProvider theme={props.theme}>
        <BigLoading></BigLoading>

        <Suspense fallback={<Splash />}>
          {!isLoading && (
            <>
              <Alert />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                style={{
                  backgroundColor: props.theme.palette.background.secundary,
                  minHeight: '100%',
                }}
              >
                <Box>
                  <Box marginBottom={header ? 2.125 : 0}>
                    <React.Fragment>{header}</React.Fragment>
                  </Box>
                  <Layout
                    components={components}
                    configs={configs}
                    noHeader={!header}
                  />
                </Box>
                <Box>
                  <React.Fragment>{footer}</React.Fragment>
                </Box>
              </Box>
            </>
          )}
        </Suspense>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default Shell
